<template>
  <div>
    <b-modal
      v-if="filterOrders.length !== 0"
      id="modal-select3"
      title="Billing"
      centered
      ok-only
      size="sm"
    >
      <div v-if="loading == true">
        <b-spinner
          variant="primary"
          type="grow"
          style="margin-left: 150px"
          class="p-2"
          label="Spinning"
        ></b-spinner>
      </div>

      <b-card v-if="loading == false">
        <b-row>
          <b-col cols="8">
            <h5>Mode</h5>
          </b-col>
          <b-col>
            <p>{{ items[myindex].modeOfPayment }}</p>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="8">
            <h5>Value Added Tax</h5>
          </b-col>
          <b-col>
            <p>{{ 'AED ' + items[myindex].vat }}</p>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="8">
            <h5>Visiting Charges</h5>
          </b-col>
          <b-col>
            <p>{{ 'AED ' + items[myindex].visitingCharges }}</p>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="8">
            <h5>Discount</h5>
          </b-col>
          <b-col>
            <p>{{ 'AED ' + items[myindex].discount }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="8">
            <h5>Bill</h5>
          </b-col>
          <b-col>
            <p>{{ 'AED ' + items[myindex].total }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="8">
            <h5>Net Bill</h5>
          </b-col>
          <b-col>
            <p>{{ 'AED ' + items[myindex].netTotal }}</p>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>

    <!-- select 2 demo -->
    <b-modal
      v-if="filterOrders.length !== 0"
      id="modal-select2"
      title="Order Details"
      centered
      ok-only
      size="md"
    >
      <!-- <div v-if="loading == false">
        <b-spinner
          variant="primary"
          type="grow"
          style="margin-left:200px;"
          class="p-2"
          label="Spinning"
        ></b-spinner>
      </div> -->

      <b-card>
        <b-row>
          <b-col cols="5">
            <b-card-img
              :src="items[myindex].cover"
              style="height: 140px; width: 140px"
            >
            </b-card-img>
          </b-col>

          <b-col cols="*">
            <b-card-text> Booking ID: {{ items[myindex].id }} </b-card-text>
            <br />
            <h3 style="margin-top:5px;">
              {{ items[myindex].name }}
            </h3>
            <b-card-text style="margin-top:5px;">
              {{
                items[myindex].category + ' -> ' + items[myindex].subcategory
              }}
            </b-card-text>
            <div style="margin-top:5px;">
              <b-badge variant="primary"> {{ items[myindex].slot }} </b-badge>
            </div>
              <div v-if="items[myindex].atHome == true " style="margin-top:5px;">
             <p>at <span class="text-primary">Home</span></p>
            </div>
            <div style="margin-top:5px;">
                 <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                size="sm"
                v-if="items[myindex].status.status === 'Accepted'"
                @click="OrderStatus(items[myindex].id, 'Completed')"
              >
                Complete
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                size="sm"
                v-if="items[myindex].status.status === 'On going'"
                @click="OrderStatus(items[myindex].id, 'Accepted')"
              >
                Accpect
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                size="sm"
                class="ml-1"
                v-if="items[myindex].status.status === 'On going'"
                @click="OrderStatus(items[myindex].id, 'Canceled')"
              >
                Cancel
              </b-button>
            </div>
          </b-col>

          <b-col> </b-col>
        </b-row>
      </b-card>
  <b-card v-if="items[myindex].note !== ''">
     <b-card-title>
    Note:
    </b-card-title>
          <b-card-text>
          {{items[myindex].note}}
          </b-card-text>
  </b-card>
  <b-card v-if="items[myindex].atHome == true">
     <b-card-title>
    Address:
    </b-card-title>
          <b-card-text>
          {{items[myindex].address}}
          </b-card-text>
  </b-card>
      <!-- <b-card>
          <b-row>
            <b-col> 
          <b-card-text>
            <b>Name: </b> {{items[0].firstName}}
          </b-card-text>
            </b-col>

             <b-col> 
          <b-card-text>
            <b>Contact: </b> {{items[0].phoneNo}}
          </b-card-text>
            </b-col>
          </b-row>
         
                <b-row class="mt-1">
            <b-col> 
          <b-card-text>
            <b>Date: </b> {{items[0].date}}
          </b-card-text>
            </b-col>

          </b-row>

            <b-row v-if="items[0].atHome == true" class="mt-1">
             <b-col> 
          <b-card-text>
            <b>Address: </b> {{items[0].address}}
          </b-card-text>
            </b-col>
          </b-row>
        </b-card>
       -->

      <!-- Control buttons-->
      <div class="text-center" v-if="this.CakeDetails.length > 1">
        <b-button-group class="mt-1">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="tabIndex--"
          >
            Previous
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="tabIndex++"
          >
            Next
          </b-button>
        </b-button-group>
      </div>
    </b-modal>
    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="Show data"
      @ok="filterData()"
      centered
      title="Filters"
    >
      <b-form>
        <b-form-group>
          <h5>Select date range</h5>
          <flat-pickr
            v-model="date"
            class="form-control"
            :config="{ mode: 'range' }"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-start">
              <!-- <div style="padding-right: 15px">
                <b-button v-b-modal.modal-login variant="primary">
                  <span class="text-nowrap">Filters</span>
                </b-button>
              </div> -->
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
        <div class="mt-1 mr-1" style="margin-bottom: -10px">
          <b-row align-h="end">
            <b-col class="pr-1" cols="*" lg="*" md="*" sm="*">
              <div>
                <b-button variant="warning" @click="GetOrders('On going')">
                  <span class="text-nowrap"
                    >Ongoing
                    <b-badge variant="primary" class="badge-glow">
                      {{ ongoing }}
                    </b-badge></span
                  >
                </b-button>
              </div>
            </b-col>

            <b-col class="pr-1" cols="*" lg="*" md="*" sm="*">
              <div>
                <b-button variant="success" @click="GetOrders('Completed')">
                  <span style="padding-left: 10px" class="text-nowrap">
                    Completed
                    <b-badge variant="primary" class="badge-glow">
                      {{ completed }}
                    </b-badge></span
                  >
                </b-button>
              </div>
            </b-col>
            <b-col class="pr-1" cols="*" lg="*" md="*" sm="*">
              <div>
                <b-button variant="success" @click="GetOrders('Accepted')">
                  <span style="padding-left: 10px" class="text-nowrap">
                    Accepted
                    <b-badge variant="primary" class="badge-glow">
                      {{ accepted }}
                    </b-badge></span
                  >
                </b-button>
              </div>
            </b-col>
            <b-col class="pr-1" cols="*" lg="*" md="*" sm="*">
              <div>
                <b-button variant="danger" @click="GetOrders('Canceled')">
                  <span style="padding-left: 10px" class="text-nowrap">
                    Canceled
                    <b-badge variant="primary" class="badge-glow">
                      {{ canceled }}
                    </b-badge></span
                  >
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>

      <div>
        <b-table
          :items="filterOrders"
          :fields="fields"
          :tbody-tr-class="table - danger"
          responsive
        >
          <template #cell(netTotal)="data">
            AED{{ ' ' + data.item.netTotal }}
          </template>

          <template #cell(status)="data">
            <b-badge pill :variant="data.value.variant">
              {{ data.value.status }}
            </b-badge>
          </template>

          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-b-modal.modal-select2
                @click="Details(data.index)"
              >
                <feather-icon icon="FileTextIcon" />

                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-b-modal.modal-select3
                @click="Details(data.index)"
              >
                <feather-icon icon="CreditCardIcon" />
                <span class="align-middle ml-50">Billing</span>
              </b-dropdown-item>

              <!-- <b-dropdown-item
                v-if="data.item.status.status === 'On going'"
                @click="OrderStatus(data.item.orderId, 'Under progress')"
              >
                <feather-icon icon="CheckIcon" />
                <span class="align-middle ml-50">Accept </span>
              </b-dropdown-item> -->

              <!-- <b-dropdown-item
                v-if="data.item.status.status === 'On going'"
                @click="OrderStatus(data.item.id, 'Accepted')"
              >
                <feather-icon icon="XIcon" />
                <span class="align-middle ml-50">Accept</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="data.item.status.status === 'On going'"
                @click="OrderStatus(data.item.id, 'Canceled')"
              >
                <feather-icon icon="XIcon" />
                <span class="align-middle ml-50">Cancel </span>
              </b-dropdown-item> -->

              <!-- <b-dropdown-item
                v-if="data.item.status.status === 'Under progress'"
                
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit </span>
              </b-dropdown-item> -->

              <b-dropdown-item
                v-if="data.item.status.status === 'Under progress'"
                @click="OrderStatus(data.item.orderId, 'Cancel')"
              >
                <feather-icon icon="DeleteIcon" />
                <span class="align-middle ml-50">Cancel </span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="data.item.status.status === 'Under progress'"
                @click="OrderStatus(data.item.orderId, 'Ready for delivery')"
              >
                <feather-icon icon="CheckCircleIcon" />
                <span class="align-middle ml-50">Ready </span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="
                  data.item.status.status === 'Ready for delivery' ||
                  data.item.status.status === 'Cancel' ||
                  data.item.status.status === 'Reject'
                "
                @click="OrderStatus(data.item.orderId, 'Archive')"
              >
                <feather-icon icon="ArchiveIcon" />
                <span class="align-middle ml-50">Archive </span>
              </b-dropdown-item>

              <!-- <b-dropdown-item @click="DenyOrder(data.item.orderId)">
                <feather-icon icon="XIcon" />
                <span class="align-middle ml-50">Reject</span>
              </b-dropdown-item> -->
            </b-dropdown>
          </template>
        </b-table>

        <b-col v-if="filterOrders.length == 0" cols="12" class="demo-spacing-0">
          <b-alert variant="primary" show>
            <div class="alert-body">
              <span
                ><strong>Empty! </strong> There is no record to display.</span
              >
            </div>
          </b-alert>
        </b-col>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalOrders"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  BAlert,
  BCardTitle,
  // BTabs,
  //BTab,
  BButtonGroup,
  BSpinner,
  BCardImg,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import flatPickr from 'vue-flatpickr-component';
import { BFormSelect } from 'bootstrap-vue';
//import Multiselect from "vue-multiselect";
export default {
  components: {
    //Multiselect,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    //BSpinner,
    BCardImg,
    BAlert,
    BCardTitle ,
    //BMedia,
    //BAvatar,
    //BLink,
    BBadge,
    BButtonGroup,
    // BTabs,
    //BTab,
    BDropdown,
    BDropdownItem,
    BPagination,
    //vSelect,
    flatPickr,
  },
  props: {
    /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
  },
  created() {
    this.LoadData();
  },
  computed: {
    filterOrders: function () {
      return this.items.filter((pro) => {
        return pro.firstName
          .toLowerCase()
          .match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      accepted: '',
      myindex: 0,
      orderTabs: ['Purchases', 'Fitting Room'],
      uaeTime: '',
      completed: '',
      ongoing: '',
      canceled: '',
      readyForDelivery: '',
      requiresActions: '',
      underProgress: '',
      date: '',
      searchQuery: '',
      loading: false,
      CakeDetails: [],
      tabIndex: 1,
      cover: '',
      fields: [
        {
          label: 'BookingId',
          key: 'id',
          isRowHeader: true,
        },
        { label: 'Customer', key: 'firstName' },
        'date',
        { label: 'Net Total', key: 'netTotal' },
        'status',
        'actions',
      ],
      items: [],
      selected: null,
      filterOptions: [
        { text: 'Most sold product', value: 'mostSold' },
        { text: 'Least sold product', value: 'leastSold' },
        { text: 'Most popular product', value: 'mostPopular' },
        { text: 'Least popular product', value: 'leastPopular' },
        { text: 'Most profitable product', value: 'mostProfitable' },
      ],
      rangeDate: null,
      myObj: {
        orderId: 0,
        userId: 0,
        bill: 0,
        vat: 0,
        discount: 0,
        modeOfpayment: '',
        vendorId: 0,
        deliveryCharges: 0,
        date: '',
        netBill: 0,
        totalBill: 0,
        transactionId: '',
        paidAmount: 0,
      },

      detailObj: {},
    };
  },
  methods: {
    Details(index) {
      this.myindex = index;
      console.log(index);
      //s this.detailObj = this.items[index];
    },
    GetOrders(status) {
      var axios = require('axios');

      var config = {
        method: 'get',
        url:
          'https://brandaway.fastech.pk/api/Bookings/GetVendorBookingsStatus/' +
          this.$store.state.userData.userID +
          '/' +
          status,
        headers: {
          Authorization: 'bearer ' + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          this.completed = response.data.completed;
          this.ongoing = response.data.ongoing;
          this.canceled = response.data.canceled;
          this.accepted = response.data.accepted;

          // this.items =  response.data.data;
          this.items = [];
          response.data.data.forEach((elem) => {
            if (elem.status == 'On going') {
              elem.status = { status: 'On going', variant: 'warning' };
              var d = new Date(elem.date);
              elem.date = d.toDateString();
              this.items.push(elem);
            }
            if (elem.status == 'Canceled') {
              elem.status = { status: 'Canceled', variant: 'danger' };
              var d = new Date(elem.date);
              elem.date = d.toDateString();
              this.items.push(elem);
            }
            if (elem.status == 'Accepted') {
              elem.status = { status: 'Accepted', variant: 'success' };
              var d = new Date(elem.date);
              elem.date = d.toDateString();
              this.items.push(elem);
            }
            if (elem.status == 'Completed') {
              elem.status = { status: 'Completed', variant: 'success' };
              var d = new Date(elem.date);
              elem.date = d.toDateString();
              this.items.push(elem);
            }
          });
          console.log('Hello  UItems', this.items);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    rowClass(item, type) {
      const colorClass = 'table-danger';
      if (!item || type !== 'row') {
        return;
      }

      // eslint-disable-next-line consistent-return
      if (item.date < this.uaeTime && item.status.status == 'Under progress') {
        return colorClass;
      }
      if (
        item.date < this.uaeTime &&
        item.status.status == 'Requires actions'
      ) {
        return colorClass;
      }
    },
    filterData() {
      console.log('Hello');
      let from = this.date.split(' ')[0];
      let to = this.date.split(' ')[2];
      console.log(from, to);
      if (from == undefined || to == undefined) {
        return this.LoadData();
      }
      //this.loading = true;
      // data
      // this.$http.get('/ecommerce/data')
      //   .then(response => {
      //     this.data = response.data

      //     // ? Your API will return name of logged in user or you might just directly get name of logged in user
      //     // ? This is just for demo purpose
      //     const userData = getUserData()
      //     this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      //   })
      var axios = require('axios');

      var config = {
        method: 'get',
        url:
          'https://okaaik.fastech.pk/api/oOrders/getoOrdersbyfilter/' +
          this.$store.state.userData.userID +
          '/?fromdate=' +
          from +
          '&todate=' +
          to,
        headers: {
          Authorization:
            'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
        },
      };

      axios(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));

          //  this.data  =  response.data;
          //console.log( response.data);
          if (response.data.status === 'success') {
            console.log('mydata', response.data);
            this.items = [];
            response.data.query.forEach((elem) => {
              elem.date = elem.date.split('T')[0];
              this.items.push(elem);
            });

            //this.data = response.data;
          }

          //   this.myProducts = response.data.data;
          //    console.log(this.myProducts);
          // if(this.myProducts.data.length == 0)
          //  {
          //    this.productEmpty = true;
          //  }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    Billing(item) {
      console.log(item);
      this.loading = true;
      var axios = require('axios');

      var config = {
        method: 'get',
        url:
          'https://okaaik.fastech.pk/api/oOrders/Detailsbyorders/' +
          item.orderId,
        headers: {
          Authorization:
            'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
        },
      };

      axios(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));

          //  this.data  =  response.data;
          //console.log(response.data.orderDetails);
          this.CakeDetails = [];
          //this.product = response.data.orderDetails;
          this.CakeDetails = response.data.orderDetails;
          console.log(this.CakeDetails);
          this.myObj.orderId = item.orderId;
          this.myObj.userId = item.userId;
          this.myObj.vendorId = item.vendorId;
          this.myObj.modeOfpayment = item.modeOfpayment;
          this.myObj.bill = item.bill;
          this.myObj.vat = item.vat;
          this.myObj.deliveryCharges = item.deliveryCharges;
          this.myObj.totalBill = item.totalBill;
          this.myObj.discount = item.discount;
          this.myObj.netBill = item.netBill;
          this.myObj.transactionId = item.transactionId;
          this.myObj.paidAmount = item.paidAmount;
          this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    openDetails(id) {
      this.loading = true;
      console.log(id);
      var axios = require('axios');

      var config = {
        method: 'get',
        url: 'https://okaaik.fastech.pk/api/oOrders/Detailsbyorders/' + id,
        headers: {
          Authorization:
            'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
        },
      };

      axios(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));

          //  this.data  =  response.data;
          console.log(response.data.orderDetails);
          this.CakeDetails = [];
          //this.product = response.data.orderDetails;
          response.data.orderDetails.forEach((elem) => {
            elem.date = elem.date.split('T')[0];
            this.CakeDetails.push(elem);
          });
          console.log(this.CakeDetails);

          this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    OrderStatus(id, status) {
      Swal.fire({
        title: 'Do you want to ' + status + ' this booking?',
        text: "You won't be able to revert this!",
        icon: 'question',
        iconColor: '#FB595f',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#FB595f',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          var axios = require('axios');
          var config = {
            method: 'put',
            url:
              'https://brandaway.fastech.pk/api/Bookings/UpdateBookingsStatus/' +
              id +
              '/' +
              status,
            headers: {
              Authorization: 'bearer ' + this.$store.state.userData.token,
            },
          };
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === 'success') {
                Swal.fire(status + '!', '', 'success').then((res) => {
                  this.LoadData();
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    DenyOrder(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reject it!',
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require('axios');
          var config = {
            method: 'put',
            url:
              'https://okaaik.fastech.pk/api/oOrders/UpdateOrderStatus/?id=' +
              id +
              '&check=false',
            headers: {
              Authorization:
                'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
            },
          };
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === 'success') {
                Swal.fire(
                  'Rejected!',
                  'the order has been rejected.',
                  'success',
                ).then((res) => {
                  this.LoadData();
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    LoadData() {
      var axios = require('axios');

      var screen = {
        method: 'get',
        url:
          'https://brandaway.fastech.pk/api/Bookings/GetVendorBookings/' +
          this.$store.state.userData.userID,
        headers: {
          Authorization: 'bearer ' + this.$store.state.userData.token,
        },
      };

      axios(screen)
        .then((response) => {
          console.log(response.data);

          //  this.data  =  response.data;
          console.log('Helloo List', response.data);

          this.completed = response.data.completed;
          this.ongoing = response.data.ongoing;
          this.canceled = response.data.canceled;
          this.accepted = response.data.accepted;
          // this.items =  response.data.data;
          this.items = [];
          response.data.data.forEach((elem) => {
            if (elem.status == 'On going') {
              elem.status = { status: 'On going', variant: 'warning' };
              var d = new Date(elem.date);
              elem.date = d.toDateString();
              this.items.push(elem);
            }
            if (elem.status == 'Canceled') {
              elem.status = { status: 'Canceled', variant: 'danger' };
              var d = new Date(elem.date);
              elem.date = d.toDateString();
              this.items.push(elem);
            }
            if (elem.status == 'Accepted') {
              elem.status = { status: 'Accepted', variant: 'success' };
              var d = new Date(elem.date);
              elem.date = d.toDateString();
              this.items.push(elem);
            }
            if (elem.status == 'Completed') {
              elem.status = { status: 'Completed', variant: 'success' };
              var d = new Date(elem.date);
              elem.date = d.toDateString();
              this.items.push(elem);
            }
          });

          //  this.rows = response.data.data;

          //console.log(this.myProducts);
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },

  // setup() {
  //   const ORDER_APP_STORE_MODULE_NAME = 'app-order'

  //   // Register module
  //   // if (!store.hasModule(ORDER_APP_STORE_MODULE_NAME)) store.registerModule(ORDER_APP_STORE_MODULE_NAME, orderStoreModule)

  //   // // UnRegister on leave
  //   // onUnmounted(() => {
  //   //   if (store.hasModule(ORDER_APP_STORE_MODULE_NAME)) store.unregisterModule(ORDER_APP_STORE_MODULE_NAME)
  //   // })

  //   /*const modalFilterOptions = [
  //     { label: 'Most sold product', value: 'mostSold' },
  //     { label: 'Least sold product', value: 'leastSold' },
  //     { label: 'Most popular product', value: 'mostPopular' },
  //     { label: 'Least popular product', value: 'leastPopular' },
  //     { label: 'Most profitable product', value: 'mostProfitable' },
  //   ]*/

  //   const {

  //     tableColumns,
  //     perPage,
  //     currentPage,
  //     totalOrders,
  //     dataMeta,
  //     perPageOptions,
  //     searchQuery,
  //     sortBy,
  //     isSortDirDesc,
  //     refOrderListTable,
  //     refetchData,

  //    // modalFilter,
  //   } = useOrdersList()

  //   return {

  //     // Sidebar
  //     //isAddNewOrderSidebarActive,

  //     fetchMyOrders,
  //     tableColumns,
  //     perPage,
  //     currentPage,
  //     totalOrders,
  //     dataMeta,
  //     perPageOptions,
  //     searchQuery,
  //     sortBy,
  //     isSortDirDesc,
  //     refOrderListTable,
  //     refetchData,
  //     // Filter
  //     avatarText,
  //     //avatarText,
  //     //modalFilterOptions,
  //     //modalFilter,

  //   }
  // },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
